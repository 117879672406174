import React,{useEffect} from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import * as jQuery from "jquery"
import AOS from 'aos';
import Works from './Pages/Works';
import Blog from './Pages/Blog';
import Credentials from './Pages/Credentials';
import Service from "./Pages/Service"
import BlogDetails from './Pages/BlogDetails';
import WorkDetails from './Pages/WorkDetails';

const App = () => {


  useEffect(()=>{

    ;(function($) {

      $(document).ready( function() {
          $(document).on('click', '.header-area .show-menu', function() {
              $(this).toggleClass('active');
              $(".header-area .navbar").toggleClass('active');
          });
          // $(document).on('click', '.header-area .navbar .close-menu', function() {
          //     $(".header-area .navbar").removeClass('active');
          // });
  
          AOS.init({
              duration: 1500,
              once: true,
          })
      });
  
  })(jQuery);
  
  
  },[])

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/works" element={<Works />} />
        <Route path="/workdetails" element={<WorkDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdetails" element={<BlogDetails />} />
        <Route path="/credentials" element={<Credentials />} />
        <Route path="/service" element={<Service/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App
import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Bg1 from "../assets/images/bg1.png"
import Me2 from "../assets/images/lead1.jpg"
import Icon2 from "../assets/images/icon2.png"
import Star2 from "../assets/images/star-2.png"
import Icon from "../assets/images/icon.svg"
import Sign from "../assets/images/sign.png"
import { Link } from 'react-router-dom'

export default function About() {
  return (

 
        <main className="main-aboutpage">
    
        <Header/>
    
            <section className="about-area">
                <div className="container">
                    <div className="d-flex about-me-wrap align-items-start gap-24">
                        <div data-aos="zoom-in">
                            <div className="about-image-box shadow-box">
                                <img src={Bg1} alt="BG" className="bg-img"/>
                                <div className="image-inner">
                                    <img src={Me2} alt="About Me"/>
                                </div>
                            </div>
                        </div>
    
                        <div className="about-details" data-aos="zoom-in">
                            <h1 className="section-heading" data-aos="fade-up"><img src={Star2} alt="Star"/> Self-summary <img src={Star2} alt="Star"/></h1>
                            <div className="about-details-inner shadow-box">
                                <img src={Icon2} alt="Star"/>
                                <h1>Syed Hamza Hoda</h1>
                                <p>I am an experienced MERN stack developer with 3+ years of expertise in crafting robust web applications, proficient in React Native for seamless mobile app development, and skilled in harnessing the power of AWS for scalable cloud solutions.</p>
                            </div>
    
                        </div>
                    </div>
    
                    <div className="row mt-24">
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="about-edc-exp about-experience shadow-box">
                                <img src={Bg1} alt="BG" className="bg-img"/>
                                <h3>EXPERIENCE</h3>
    
                                <ul>
                                    <li>
                                        <p className="date">2022 - Present</p>
                                        <h2>Founding Product Engineer</h2>
                                        <p className="type">YPay Financial Services</p>
                                    </li>
                                    <li>
                                        <p className="date">2021 - 2022</p>
                                        <h2>Product Lead</h2>
                                        <p className="type">YPay Financial Services</p>
                                    </li>
                                    <li>
                                        <p className="date">2020 - 2021</p>
                                        <h2>Tech Intern</h2>
                                        <p className="type">YPay Financial Services</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6" data-aos="zoom-in">
                            <div className="about-edc-exp about-education shadow-box">
                                <img src={Bg1} alt="BG" className="bg-img"/>
                                <h3>EDUCATION</h3>
    
                                <ul>
                                    <li>
                                        <p className="date">2020 - 2024</p>
                                        <h2>Bachelor Degree in Computer Science</h2>
                                        <p className="type">Usman University</p>
                                    </li>
                                    <li>
                                        <p className="date">2017 - 2019</p>
                                        <h2>Intermediate Pre-Engineering</h2>
                                        <p className="type">Government Dehli College</p>
                                    </li>
                                    <li>
                                        <p className="date">2016 - 2017</p>
                                        <h2>Matriculation Computer Science</h2>
                                        <p className="type">Little Folk's School</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    
                    <div className="row mt-24">
                        <div className="col-md-12">
                            <div className="d-flex profile-contact-credentials-wrap gap-24">
    
                                <div data-aos="zoom-in">
                                    <div className="about-profile-box info-box shadow-box h-full">
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <div className="inner-profile-icons shadow-box">
                                            <a href="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/" target='__blank'>
                                                <i className="iconoir-linkedin"></i>
                                            </a>
                                            <a href="https://www.instagram.com/syedhamzahoda/" target='__blank'>
                                                <i className="iconoir-instagram"></i>
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>Stay with me</h4>
                                                <h1>Contact</h1>
                                            </div>
        
                                            <Link to="/contact" className="about-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link>
        
                                        </div>
                                    </div>
                                </div>
    
                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="about-contact-box info-box shadow-box">
                                        <Link className="overlay-link" to="/contact"></Link>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <img src={Icon2} alt="Icon" className="star-icon"/>
                                        <h1>Let's <br/>work <span>together.</span></h1>
                                        <Link to="/contact" className="about-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link>
                                    </div>
                                </div>
    
                                <div data-aos="zoom-in" className="h-full">
                                    <div className="about-crenditials-box info-box shadow-box">
                                        <Link className="overlay-link" to="/credentials"></Link>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <img src={Sign} alt="Sign"/>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>know me better</h4>
                                                <h1>About Me</h1>
                                            </div>
        
                                            <Link to="/credentials" className="about-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link>
        
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
    
         <Footer/>
        </main>
    )
}

import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Bg1 from "../assets/images/bg1.png"
import Me from "../assets/images/me.png"
import Icon from "../assets/images/icon.svg"
import Star1 from "../assets/images/star1.svg"
import Sign from "../assets/images/sign.png"
import Works from "../assets/images/my-works.png"
import Gfont from "../assets/images/gfonts.png"
import Icon2 from "../assets/images/icon2.png"
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <main className="main-homepage">
        <Header/>

        <section className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-6" data-aos="zoom-in">
                        <div className="about-me-box shadow-box">
                            <Link className="overlay-link" to="/about"></Link>
                            <img src={Bg1} alt="BG" className="bg-img"/>
                            <div className="img-box">
                                <img src={Me} alt="About Me"/>
                            </div>
                            <div className="infos">
                                <h4>A WEB DEVELOPER</h4>
                                <h1>Syed Hamza.</h1>
                                <p>I am a MERN stack Web and App Developer.</p>
                                <a href="#" className="about-btn">
                                    <img src={Icon} alt="Button"/>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="about-credentials-wrap">
                            <div data-aos="zoom-in">
                                <div className="banner shadow-box">
                                    <div className="marquee">
                                        <div>
                                          <span>THANK YOU FOR VIEWING MY <b>PORTFOLIO</b><img src={Star1} alt="Star"/> FEEL FREE TO <b>CONTACT</b> ME<img src={Star1} alt="Star"/> YOU CAN VIEW MY <b>LATEST</b> WORK<img src={Star1} alt="Star"/>HAVE <b>DIVERSE</b> SKILL SET<img src={Star1} alt="Star"/> WOULD LOVE TO WORK WITH <b>YOU</b><img src={Star1} alt="Star"/> THANK YOU FOR VIEWING MY <b>PORTFOLIO</b><img src={Star1} alt="Star"/></span>
                                          {/* <span>LATEST WORK AND <b>FEATURED</b> <img src={Star1} alt="Star"/> LATEST WORK AND <b>FEATURED</b> <img src={Star1} alt="Star"/> LATEST WORK AND <b>FEATURED</b> <img src={Star1} alt="Star"/> LATEST WORK AND <b>FEATURED</b> LATEST WORK AND <img src={Star1} alt="Star"/> LATEST WORK AND <b>FEATURED</b> LATEST WORK AND <img src={Star1} alt="Star"/></span> */}
                                        </div>
                                      </div>
                                </div>

                            </div>

                            <div className="gx-row d-flex gap-24">
                                <div data-aos="zoom-in">
                                    <div className="about-crenditials-box info-box shadow-box h-full">
                                        <Link className="overlay-link" to="/credentials"></Link>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <img src={Sign} alt="Sign"/>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>know me better</h4>
                                                <h1>About Me</h1>
                                            </div>
    
                                            <Link to="/credentials" className="about-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link>
    
                                        </div>
                                    </div>
                                </div>

                                <div data-aos="zoom-in">
                                    <div className="about-project-box info-box shadow-box h-full">
                                        <Link className="overlay-link" to="/works"></Link>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <img src={Works} alt="My Works"/>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>SHOWCASE</h4>
                                                <h1>Projects</h1>
                                            </div>
    
                                            <a href="#" className="about-btn">
                                                <img src={Icon} alt="Button"/>
                                            </a>
    
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-24">
                    <div className="col-md-12">
                        <div className="blog-service-profile-wrap d-flex gap-24">
                            <div data-aos="zoom-in">
                                <div className="about-blog-box info-box shadow-box h-full">
                                    <Link to="/blog" className="overlay-link"></Link>
                                    <img src={Bg1} alt="BG" className="bg-img"/>
                                    <img src={Gfont} alt="GFonts"/>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h4>Blog</h4>
                                            <h1>Learn</h1>
                                        </div>
    
                                        <Link to="/blog" className="about-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link>
    
                                    </div>
                                </div>
                            </div>

                            <div data-aos="zoom-in" className="flex-1">
                                <div className="about-services-box info-box shadow-box h-full">
                                    <Link to="/service" className="overlay-link"></Link>
                                    <img src={Bg1} alt="BG" className="bg-img"/>
                                    <div className="icon-boxes">
                                        <i className="iconoir-cloud"></i>
                                        <i className="iconoir-design-pencil"></i>
                                        <i className="iconoir-color-filter"></i>
                                        <i className="iconoir-dev-mode-phone"></i>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h4>specialization</h4>
                                            <h1>Services Offering</h1>
                                        </div>
    
                                        <Link to="/service" className="about-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link>
    
                                    </div>
                                </div>
                            </div>

                            <div data-aos="zoom-in">
                                <div className="about-profile-box info-box shadow-box h-full">
                                    <img src={Bg1} alt="BG" className="bg-img"/>
                                    <div className="inner-profile-icons shadow-box">
                                        <a href="#">
                                            <i className="iconoir-dribbble"></i>
                                        </a>
                                        <a href="#">
                                            <i className="iconoir-twitter"></i>
                                        </a>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h4>Stay with me</h4>
                                            <h1>Contact</h1>
                                        </div>
    
                                        <Link to="/contact" className="about-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link>
    
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>

                <div className="row mt-24">
                    <div className="col-md-6" data-aos="zoom-in">
                            
                        <div className="about-client-box info-box shadow-box">
                            <img src={Bg1} alt="BG" className="bg-img"/>
                            <div className="clients d-flex align-items-start gap-24 justify-content-center">
                                <div className="client-item">
                                    <h1>+03</h1>
                                    <p>Years <br/>Experience</p>
                                </div>

                                <div className="client-item">
                                    <h1>+20</h1>
                                    <p>CLIENTS <br/>WORLDWIDE</p>
                                </div>

                                <div className="client-item">
                                    <h1>+80</h1>
                                    <p>Total <br/>Projects</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6" data-aos="zoom-in">
                            
                        <div className="about-contact-box info-box shadow-box">
                            <Link className="overlay-link" to="/contact"></Link>
                            <img src={Bg1} alt="BG" className="bg-img"/>
                            <img src={Icon2} alt="Icon" className="star-icon"/>
                            <h1>Let's <br/>work <span>together.</span></h1>
                            <a href="#" className="about-btn">
                                <img src={Icon} alt="Button"/>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </section>




        <Footer/>
    </main>
  )
}

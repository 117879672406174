import React from 'react'
import Logo from "../assets/images/logo.png"
import { Link, NavLink } from 'react-router-dom'

export default function Header() {
  return (

<header className="header-area">
            <div className="container">
                <div className="gx-row d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo">
                        <img style={{maxWidth:"200px"}} src={Logo} alt="Logo"/>
                    </Link>

                    <nav className="navbar">
                        <ul className="menu">
                            <li ><NavLink className={({ isActive }) => (isActive ? 'navitem-active' : '')}
 to="/">Home</NavLink></li>
                            <li><NavLink className={({ isActive }) => (isActive ? 'navitem-active' : '')} to="/about">About</NavLink></li>
                            <li><NavLink className={({ isActive }) => (isActive ? 'navitem-active' : '')} to="/works">Works</NavLink></li>
                            <li><NavLink className={({ isActive }) => (isActive ? 'navitem-active' : '')} to="/contact">Contact</NavLink></li>
                        </ul>
                        {/* <Link to="/contact=" className="theme-btn">Let's talk</Link> */}
                    </nav>

                    <Link to="/contact" className="theme-btn">Let's talk</Link>

                    <div className="show-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>  
    )
}

import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Star2 from "../assets/images/star-2.png"
import Bg1 from "../assets/images/bg1.png"
import Icon from "../assets/images/icon.svg"
import Sign from "../assets/images/sign.png"
import Icon2 from "../assets/images/icon2.png"
import { Link } from 'react-router-dom'

export default function Service() {
  return (

        <main className="main-homepage">
    
          <Header/>
    
            <section className="service-area">
                <div className="container">
                    <h1 className="section-heading" data-aos="fade-up"><img src={Star2} alt="Star"/> My Offerings <img src={Star2} alt="Star"/></h1>
                    
                    <div className="row">
    
                        <div className="col-md-4">
                            <div className="service-sidebar" data-aos="fade-right">
                                <div className="service-sidebar-inner shadow-box">
                                    <ul>
                                        <li>
                                            <i className="iconoir-cloud icon"></i>
                                            CLOUD
                                        </li>
                                        <li>
                                            <i className="iconoir-design-pencil icon"></i>
                                            WEB DESIGNING
                                        </li>
                                        <li>
                                            <i className="iconoir-color-filter icon"></i>
                                            BRANDING
                                        </li>
                                        <li>
                                            <i className="iconoir-dev-mode-phone icon"></i>
                                            DEVELOPMENT
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div className="col-md-8">
                            <h1 className="section-heading" data-aos="fade-up"><img src={Star2} alt="Star"/> My Offerings <img src={Star2} alt="Star"/></h1>
    
                            <div className="service-content-wrap" data-aos="zoom-in">
                                <div className="service-content-inner shadow-box">
                                    <div className="service-items">
                                        <div className="service-item">
                                            <h3>Cloud</h3>
                                            <p>With expertise in AWS and cloud solutions, I offer businesses secure and scalable services. From setting up servers to integrating databases and cloud storage, my solutions ensure seamless performance and reliability.</p>
                                        </div>
                                        <div className="service-item">
                                            <h3>Web Designing</h3>
                                            <p>As a web design specialist, I create visually captivating and user-friendly website designs. Tailored to each client's needs and branding, my designs guarantee responsive and intuitive interfaces for an exceptional user experience.</p>
                                        </div>
                                        <div className="service-item">
                                            <h3>Branding</h3>
                                            <p>I provide comprehensive branding strategies to establish strong brand identities. From developing effective strategies to designing logos and visual assets, I ensure consistent brand representation.</p>
                                        </div>
                                        <div className="service-item">
                                            <h3>Development</h3>
                                            <p>Offering a range of development services, I excel in crafting feature-rich web applications using the MERN stack. With React Native, I build efficient and user-friendly mobile apps for both iOS and Android platforms. Additionally, I optimize existing codebases for improved performance and offer reliable application deployment and maintenance support.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
    
                    <div className="row mt-24">
                        <div className="col-md-12">
                            <div className="d-flex profile-contact-credentials-wrap gap-24">
    
                                <div data-aos="zoom-in">
                                    <div className="about-profile-box info-box shadow-box h-full">
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <div className="inner-profile-icons shadow-box">
                                            <a href="#">
                                                <i className="iconoir-dribbble"></i>
                                            </a>
                                            <a href="#">
                                                <i className="iconoir-twitter"></i>
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>Stay with me</h4>
                                                <h1>Contact</h1>
                                            </div>
        
                                            <Link to="/contact" className="about-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link>
        
                                        </div>
                                    </div>
                                </div>
    
                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="about-contact-box info-box shadow-box">
                                        <Link className="overlay-link" to="/contact"></Link>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <img src={Icon2} alt="Icon" className="star-icon"/>
                                        <h1>Let's <br/>work <span>together.</span></h1>
                                        <Link to="/contact" className="about-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link>
                                    </div>
                                </div>
    
                                <div data-aos="zoom-in" className="h-full">
                                    <div className="about-crenditials-box info-box shadow-box">
                                        <Link className="overlay-link" to="/credentials"></Link>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <img src={Sign} alt="Sign"/>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="infos">
                                                <h4>know me better</h4>
                                                <h1>About Me</h1>
                                            </div>
        
                                            <Link to="/credentials" className="about-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link>
        
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
    
         
         <Footer/>

        </main>
        
    
      )
}

import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Star2 from "../assets/images/star-2.png"
import Blog1 from "../assets/images/blog1.webp"
import Blog2 from "../assets/images/blog2.webp"
import Blog3 from "../assets/images/blog3.webp"
import Blog4 from "../assets/images/blog4.webp"
import Blog5 from "../assets/images/blog5.webp"
import { Link } from 'react-router-dom'

export default function Blog() {
  return (
    
        <main className="main-workdetails-page">
    
<Header/>
    
            <section className="breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb-content" data-aos="fade-up">
                        <p>HOME - BLOG</p>
                        <h1 className="section-heading"><img src={Star2} alt="Star"/> BlogS <a href="https://medium.com/@syedhamzahoda" target='__blank'>Medium</a> <img src={Star2} alt="Star"/></h1>
                    </div>
                </div>
            </section>
    
            <section className="blog-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="blog-items">
                                <div className="blog-item" data-aos="zoom-in">
                                    <div className="img-box">
                                        <img src={Blog1} alt="Blog"/>
                                    </div>
                                    <div className="content">
                                        <span className="meta">30 May 2023 -  Claps (25)</span>
                                        {/* <h1><Link to="/blogdetails">WordPress WooCommerce with Firebase</Link></h1> */}
                                        <h1><a href="https://medium.com/@syedhamzahoda/wordpress-woocommerce-integration-with-firebase-335a4e3cb124" target='__blank' >WordPress WooCommerce with Firebase</a></h1>
                                        <p>We will see how we can add our data from WordPress WooCommerce to Firebase Firestore using Firebase cloud functions. Set up a local WordPress site You will need to set up a server and a database in order.</p>
                                        {/* <Link to="/blogdetails" className="theme-btn">Read More</Link> */}
                                        <a href="https://medium.com/@syedhamzahoda/wordpress-woocommerce-integration-with-firebase-335a4e3cb124" target='__blank' className="theme-btn">Read More</a>
                                    </div>
                                </div>
    
                                <div className="blog-item" data-aos="zoom-in">
                                    <div className="img-box">
                                        <img src={Blog2} alt="Blog"/>
                                    </div>
                                    <div className="content">
                                        <span className="meta">29 May 2023 -  Claps (1)</span>
                                        {/* <h1><Link to="/blogdetails">Setting WooCommerce with WordPress.</Link></h1> */}
                                        <h1><a href="https://medium.com/@syedhamzahoda/setting-woocommerce-with-wordpress-and-creating-first-product-274eee878b66" target='__blank'>Setting WooCommerce with WordPress.</a></h1>
                                        <p>We will see how we can set up WooCommerce plugin with WordPress and create our first product. Set up WooCommerce store.</p>
                                        {/* <Link to="/blogdetails" className="theme-btn">Read More</Link> */}
                                        <a href="https://medium.com/@syedhamzahoda/setting-woocommerce-with-wordpress-and-creating-first-product-274eee878b66" target='__blank' className="theme-btn">Read More</a>
                                    </div>
                                </div>
                                
                                <div className="blog-item" data-aos="zoom-in">
                                    <div className="img-box">
                                        <img src={Blog3} alt="Blog"/>
                                    </div>
                                    <div className="content">
                                        <span className="meta">21 May 2023 -  Claps (1)</span>
                                        {/* <h1><Link to="/blogdetails">Install WordPress on localhost XAMPP.</Link></h1> */}
                                        <h1><a href="https://medium.com/@syedhamzahoda/install-wordpress-on-localhost-xampp-b77737ba8f11" target='__blank'>Install WordPress on localhost XAMPP.</a></h1>
                                        <p>Installing WordPress on Localhost using XAMPP server. Prerequisites The following needs to be installed. WordPress.</p>
                                        {/* <Link to="/blogdetails" className="theme-btn">Read More</Link> */}
                                        <a href="https://medium.com/@syedhamzahoda/install-wordpress-on-localhost-xampp-b77737ba8f11" target='__blank' className="theme-btn">Read More</a>
                                    </div>
                                </div>

                                <div className="blog-item" data-aos="zoom-in">
                                    <div className="img-box">
                                        <img src={Blog4} alt="Blog"/>
                                    </div>
                                    <div className="content">
                                        <span className="meta">17 Jan 2023 -  Claps (2)</span>
                                        {/* <h1><Link to="/blogdetails">Deploy React.js app on Firebase</Link></h1> */}
                                        <h1><a href="https://medium.com/@syedhamzahoda/deploy-react-js-app-on-firebase-41e6ae01c36d" target='__blank'>Deploy React.js app on Firebase</a></h1>
                                        <p>Deploying React app by creating a project from the Firebase console. Prerequisites The following needs to be installed globally create-react-app and firebase-tools.</p>
                                        {/* <Link to="/blogdetails" className="theme-btn">Read More</Link> */}
                                        <a href="https://medium.com/@syedhamzahoda/deploy-react-js-app-on-firebase-41e6ae01c36d" target='__blank' className="theme-btn">Read More</a>
                                    </div>
                                </div>

                                <div className="blog-item" data-aos="zoom-in">
                                    <div className="img-box">
                                        <img src={Blog5} alt="Blog"/>
                                    </div>
                                    <div className="content">
                                        <span className="meta">17 Jan 2023 -  Claps (10)</span>
                                        {/* <h1><Link to="/blogdetails">Create an API using Node js</Link></h1> */}
                                        <h1><a href="https://medium.com/@syedhamzahoda/create-an-api-using-node-js-8d7e83f43176" target='__blank'>Create an API using Node js</a></h1>
                                        <p>What is an API? API stands for Application Programming Interface. API architecture is usually explained in terms of client and server. The application sending the request is called the client, and the application.</p>
                                        {/* <Link to="/blogdetails" className="theme-btn">Read More</Link> */}
                                        <a href="https://medium.com/@syedhamzahoda/create-an-api-using-node-js-8d7e83f43176" target='__blank' className="theme-btn">Read More</a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blog-sidebar">
                                <div className="blog-sidebar-inner">
    
                                    {/* <div className="blog-sidebar-widget search-widget">
                                        <div className="blog-sidebar-widget-inner" data-aos="zoom-in">
                                            <form className="shadow-box">
                                                <input type="text" placeholder="Search Here..."/>
                                                <button className="theme-btn">Search</button>
                                            </form>
                                        </div>
                                    </div> */}
    
                                    {/* <div className="blog-sidebar-widget recent-post-widget" data-aos="zoom-in">
                                        <div className="blog-sidebar-widget-inner shadow-box">
                                            <h3>Recent Posts</h3>
    
                                            <ul>
                                                <li><Link to="/blogdetails">Consulted admitting is power acuteness.</Link></li>
                                                <li><Link to="/blogdetails">Unsatiable entreaties may collecting Power.</Link></li>
                                                <li><Link to="/blogdetails">Discovery incommode earnestly no he commanded</Link></li>
                                                <li><Link to="/blogdetails">Unsatiable entreaties may collecting Power.</Link></li>
                                            </ul>
    
                                        </div>
                                    </div> */}
    
                                    <div className="blog-sidebar-widget categories-widget" data-aos="zoom-in">
                                        <div className="blog-sidebar-widget-inner shadow-box">
                                            <h3>Categories</h3>
    
                                            <ul>
                                                <li><Link >-Development</Link></li>
                                                <li><Link >-Deployment</Link></li>
                                                <li><Link >-E-Commerce</Link></li>
                                                <li><Link >-Cloud</Link></li>
                                                <li><Link >-API</Link></li>
                                            </ul>
    
                                        </div>
                                    </div>
    
                                    <div className="blog-sidebar-widget tags-widget" data-aos="zoom-in">
                                        <div className="blog-sidebar-widget-inner shadow-box">
                                            <h3>Tags</h3>
    
                                            <ul>
                                                <li><Link className="theme-btn" >Firebase</Link></li>
                                                <li><Link className="theme-btn" >Development</Link></li>
                                                <li><Link className="theme-btn" >UI/UX</Link></li>
                                                <li><Link className="theme-btn" >Web</Link></li>
                                                <li><Link className="theme-btn" >Localhost</Link></li>
                                                <li><Link className="theme-btn" >Reactjs</Link></li>
                                                <li><Link className="theme-btn" >WordPress</Link></li>
                                            </ul>
    
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
         <Footer/>
    
        </main>
        
    
      )
}

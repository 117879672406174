import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

import Bg1 from "../assets/images/bg1.png"
import Me from "../assets/images/me.png"
import { Link } from 'react-router-dom'

export default function Credentials() {
  return (

    
        <main className="main-aboutpage">
    
           <Header/>
    
            <section className="credential-area">
                <div className="container">
                    <div className="gx-row d-flex">
                        <div className="credential-sidebar-wrap" data-aos="zoom-in">
                            <div className="credential-sidebar text-center">
                                <div className="shadow-box">
                                    <img src={Bg1} alt="BG" className="bg-img"/>
                                    <div className="img-box">
                                        <img src={Me} alt="About Me"/>
                                    </div>
                                    <h2>Syed Hamza</h2>
                                    <p><a href='https://linktr.ee/syedhamzahoda' target='__blank'>@syedhamzahoda</a></p>
            
                                    <ul className="social-links d-flex justify-content-center">
                                        <li><a target='__blank' href="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/"><i className="iconoir-linkedin"></i></a></li>
                                        <li><a target='__blank' href="https://twitter.com/syedhamzahoda"><i className="iconoir-twitter"></i></a></li>
                                        <li><a target='__blank' href="https://www.instagram.com/syedhamzahoda/"><i className="iconoir-instagram"></i></a></li>
                                        <li><a target='__blank' href="https://www.facebook.com/hamza.hoda.3/"><i className="iconoir-facebook-tag"></i></a></li>
                                        <li><a target='__blank' href="https://www.github.com/hamzahoda"><i className="iconoir-github-circle"></i></a></li>
                                    </ul>
            
                                    <Link to="/contact" className="theme-btn">Contact Me</Link>
                                </div>
                            </div>
                        </div>
    
                        <div className="credential-content flex-1">
                            <div className="credential-about" data-aos="zoom-in">
                                <h2>About Me</h2>
                                <p>I am an experienced MERN stack developer with 3+ years of expertise in crafting robust web applications, proficient in React Native for seamless mobile app development, and skilled in harnessing the power of AWS for scalable cloud-based solutions. My commitment to writing clean code and staying updated with the latest industry trends ensures that I deliver high-quality results.</p>
                                <p>With a diverse range of experience across various fields and industries, I bring a unique perspective to every project, fostering creativity and adaptability.</p>
                            </div>
    
                            <div className="credential-edc-exp credential-experience">
                                <h2 data-aos="fade-up">Experience</h2>
                                <div className="credential-edc-exp-item" data-aos="zoom-in">
                                    <h4>2022 - Present</h4>
                                    <h3>Founding Product Engineer</h3>
                                    <h5>YPay Financial Services</h5>
                                    <p>Experienced developer proficient in HTML, CSS, React, React Native, Node, Firebase and AWS for website and app creation and deployment. Skilled in CICD pipelines, AWS resource management, and API integration. Customer-centric customization, seamless collaboration with UI/UX and research teams, and a focus on product quality and cleanliness.</p>
                                </div>
                                <div className="credential-edc-exp-item" data-aos="zoom-in">
                                    <h4>2021 - 2022</h4>
                                    <h3>Product Lead</h3>
                                    <h5>YPay Financial Services</h5>
                                    <p>Versatile developer, creating and deploying websites and apps using HTML, CSS, React, React Native, Node,Firebase, and AWS. I specialize in customizing products to meet specific customer needs and seamlessly integrating APIs with the frontend. Working in harmony with Ui/Ux and research teams, I am dedicated to improving product quality and maintaining a clean codebase to ensure exceptional user experiences.</p>
                                </div>
                                <div className="credential-edc-exp-item" data-aos="zoom-in">
                                    <h4>2020 - 2021</h4>
                                    <h3>Tech Intern</h3>
                                    <h5>YPay Financial Services</h5>
                                    <p>Skilled web developer responsible for creating and managing the company's website, utilizing HTML, CSS, Bootstrap, and PHP to deliver an impressive online presence. Handling all aspects of the website, including adding updates and ensuring it remains relevant and user-friendly for the company's target audience. guaranteeing a seamless and professional digital experience that aligns with the company's goals and objectives</p>
                                </div>
                            </div>
    
                            <div className="credential-edc-exp credential-education">
                                <h2 data-aos="fade-up">Education</h2>
                                <div className="credential-edc-exp-item" data-aos="zoom-in">
                                    <h4>2020 - 2024</h4>
                                    <h3>Bachelor Degree in Computer Science</h3>
                                    <h5>Usman University</h5>
                                    <p>Understanding of fundamental computing principles, programming languages, algorithms, data structures, and software development. This educational background provides a strong foundation to excel in various IT roles, contributing to the design, development, and implementation of innovative technological solutions.</p>
                                </div>
                                <div className="credential-edc-exp-item" data-aos="zoom-in">
                                    <h4>2017 - 2019</h4>
                                    <h3>Intermediate Pre-Engineering</h3>
                                    <h5>Government Dehli College</h5>
                                    <p>A broad range of subjects related to the fields of science and mathematics, with a particular focus on physics, chemistry, and mathematics. This educational background lays a strong foundation for pursuing higher studies and careers in engineering, technology, and related scientific disciplines, providing them with the necessary knowledge and skills to excel in these fields.</p>
                                </div>
                                <div className="credential-edc-exp-item" data-aos="zoom-in">
                                    <h4>2016 - 2017</h4>
                                    <h3>Matriculation Computer Science</h3>
                                    <h5>Little Folks School</h5>
                                    <p>Gaining essential knowledge and skills in the fundamentals of computer science, programming, and information technology. This educational qualification provides a solid starting point for pursuing further studies or entering the technology industry, equipped with a basic understanding of computer systems, programming concepts, and problem-solving abilities.</p>
                                </div>
                            </div>
    
                            <div className="skills-wrap">
                                <h2 data-aos="fade-up">Skills</h2>
                                <div className="d-grid skill-items gap-24 flex-wrap">
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">85%</span>
                                        <h3 className="name">JavaScript</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">78%</span>
                                        <h3 className="name">Python</h3>
                                        <p>Intermediate level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">52%</span>
                                        <h3 className="name">Figma</h3>
                                        <p>Intermediate level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">80%</span>
                                        <h3 className="name">WordPress</h3>
                                        <p>Intermediate level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">91%</span>
                                        <h3 className="name">React</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">87%</span>
                                        <h3 className="name">Node</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">85%</span>
                                        <h3 className="name">AWS</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">83%</span>
                                        <h3 className="name">CICD</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">95%</span>
                                        <h3 className="name">Firebase</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">88%</span>
                                        <h3 className="name">MongoDb</h3>
                                        <p>Intermediate level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">92%</span>
                                        <h3 className="name">React Native</h3>
                                        <p>Advance level</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">87%</span>
                                        <h3 className="name">SQL</h3>
                                        <p>Intermediate level</p>
                                    </div>
    
                                </div>
                            </div>


                            <div className="skills-wrap awards-wrap">
                                <h2 data-aos="fade-up">Volunteer</h2>
                                <div className="d-grid skill-items gap-24 flex-wrap">
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">2022</span>
                                        <h3 className="name">Open-source Contributor</h3>
                                        <p>HacktoberFest22</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">2022 - 2023</span>
                                        <h3 className="name">GDSC-UIT Lead</h3>
                                        <p>Google Developer Student Clubs</p>
                                    </div>
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">2021 - 2022</span>
                                        <h3 className="name">Core Team Member</h3>
                                        <p>Google Developer Student Clubs</p>
                                    </div>
    
                                </div>
                            </div>

                            
                            <div className="skills-wrap awards-wrap">
                                <h2 data-aos="fade-up">Certificates</h2>
                                <div className="d-grid skill-items gap-24 flex-wrap">
                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">2022</span>
                                        <h3 className="name">Certified Python Developer</h3>
                                        <p>CISCO</p>
                                    </div>

                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">2021 - 2022</span>
                                        <h3 className="name">WordPress Developer</h3>
                                        <p>Digi Skill</p>
                                    </div>

                                    <div className="skill-item" data-aos="zoom-in">
                                        <span className="percent">2022</span>
                                        <h3 className="name">Introduction to Python</h3>
                                        <p>DataCamp</p>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
    
    
        <Footer/>
        </main>
        
      )
}

import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Star2 from "../assets/images/star-2.png"
import Bg1 from "../assets/images/bg1.png"
import Icon from  "../assets/images/icon.svg"
import Project1 from "../assets/images/project1.png"
import Project2 from "../assets/images/project2.jpg"
import Project3 from "../assets/images/project3.jpg"
import Project4 from "../assets/images/project4.png"
import Project5 from "../assets/images/project5.png"
import Project6 from "../assets/images/project6.png"
import { Link } from 'react-router-dom'

export default function Works() {
  return (

    
        <main className="main-workspage">
    
          <Header/>

            <section className="projects-area">
                <div className="container">
                    <h1 className="section-heading" data-aos="fade-up"><img src={Star2} alt="Star"/> All Projects <img src={Star2} alt="Star"/></h1>
                    <div className="row">
                        <div className="col-md-4">
                            <div data-aos="zoom-in">
                                <div className="project-item shadow-box">
                                    {/* <Link className="overlay-link" to="/workdetails"></Link> */}
                                    <a className="overlay-link" href="https://ypayfinancial.com" target='__blank'></a>
                                    <img src={Bg1} alt="BG" className="bg-img"/>
                                    <div className="project-img">
                                        <img src={Project1} alt="Project"/>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="project-info">
                                            <p>WEB DESIGNING & DEVELOPMENT</p>
                                            <h1>YPay</h1>
                                        </div>
                                        {/* <Link to="/workdetails" className="project-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link> */}
                                         <a href="https://ypayfinancial.com" target='__blank' className="project-btn">
                                            <img src={Icon} alt="Button"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div data-aos="zoom-in">
                                <div className="project-item shadow-box">
                                    {/* <Link className="overlay-link" to="/workdetails"></Link> */}
                                    <a href='https://play.google.com/store/apps/details?id=com.ypayapp.pack' target='__blank' className="overlay-link" ></a>
                                    <img src={Bg1} alt="BG" className="bg-img"/>
                                    <div className="project-img">
                                        <img src={Project2} alt="Project"/>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="project-info">
                                            <p>APP DESIGNING & DEVELOPMENT</p>
                                            <h1>YPay</h1>
                                        </div>
                                        {/* <Link to="/workdetails" className="project-btn">
                                            <img src={Icon} alt="Button"/>
                                        </Link> */}
                                        <a href='https://play.google.com/store/apps/details?id=com.ypayapp.pack' target='__blank' className="project-btn">
                                            <img src={Icon} alt="Button"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-8">
                            <h1 className="section-heading" data-aos="fade-up"><img src={Star2} alt="Star"/> All Projects <img src={Star2} alt="Star"/></h1>
                            

                            <div className="d-flex align-items-start gap-24">
                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="project-item shadow-box">
                                        {/* <Link className="overlay-link" to="/workdetails"></Link> */}
                                        <a className="overlay-link" target='__blank' href="https://zain-pasha.web.app/"></a>

                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <div className="project-img">
                                            <img src={Project5} alt="Project"/>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="project-info">
                                                <p>WEB DESIGNING & DEVELOPMENT</p>
                                                <h1>Weberse</h1>
                                            </div>
                                            {/* <Link to="/workdetails" className="project-btn"> */}
                                            <a href="https://zain-pasha.web.app/" target='__blank' className="project-btn">
                                                <img src={Icon} alt="Button"/>
                                            {/* </Link> */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
    
                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="project-item shadow-box">
                                        {/* <Link className="overlay-link" to="/workdetails"></Link> */}
                                        <a className="overlay-link" target='__blank' href="https://freestand.com"></a>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <div className="project-img">
                                            <img src={Project6} alt="Project"/>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="project-info">
                                                <p>wEB DESIGNING</p>
                                                <h1>Freestand</h1>
                                            </div>
                                            {/* <Link to="/workdetails" className="project-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link> */}
                                            <a href="https://freestand.com" target='__blank' className="project-btn">
                                                <img src={Icon} alt="Button"/>
                                            </a>
                                        </div>
                                    </div>
    
                                </div>
                            </div>



                            <div className="d-flex align-items-start gap-24">
                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="project-item shadow-box">
                                        {/* <Link className="overlay-link" to="/workdetails"></Link> */}
                                        <a className="overlay-link" href="https://play.google.com/store/apps/details?id=com.connecthear.customer" target='__blank'></a>
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <div className="project-img">
                                            <img src={Project3} alt="Project"/>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="project-info">
                                                <p>APP DESIGNING & DEVELOPMENT</p>
                                                <h1>Connect Hear</h1>
                                            </div>
                                            {/* <Link to="/workdetails" className="project-btn">
                                                <img src={Icon} alt="Button"/>
                                            </Link> */}
                                            <a href="https://play.google.com/store/apps/details?id=com.connecthear.customer" target='__blank' className="project-btn">
                                                <img src={Icon} alt="Button"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div data-aos="zoom-in" className="flex-1">
                                    <div className="project-item shadow-box">
                                        {/* <Link className="overlay-link" to="/workdetails"></Link> */}
                                        <img src={Bg1} alt="BG" className="bg-img"/>
                                        <div className="project-img">
                                            <img src={Project4} alt="Project"/>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="project-info">
                                                <p>APP DESIGNING AND DEVELOPMENT</p>
                                                <h1>Mates Rates</h1>
                                            </div>
                                            {/* <Link to="/workdetails" className="project-btn"> */}
                                                <img src={Icon} alt="Button"/>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
    
                        </div>
    
                    </div>
                </div>
            </section>
            
    
    
          <Footer/>
    
        </main>
        
    
    )
}

import React from 'react'
import Logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className="footer-area">
    <div className="container">
        <div className="footer-content text-center">
            <Link to="/" className="logo">
                <img style={{maxWidth:"200px"}} src={Logo} alt="Logo"/>
            </Link>
            <ul className="footer-menu">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/works">Works</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
            <p className="copyright">
                &copy; All rights reserved by <span>Syed Hamza Hoda</span>
            </p>
        </div>
    </div>
</footer>
  )
}

import React,{useState} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Bg1 from "../assets/images/bg1.png"
import Icon3 from "../assets/images/icon3.png"
import axios from "axios"

export default function Contact() {

    const [response,setResponse] = useState({
        username:"",
        email:"",
        subject:"",
        message:""
    })

    const [showMessage, setShowMessage] = useState("")

const sendEmail =() =>{

    axios({
        method: 'post', //you can set what request you want to be
        url:"https://api.emailjs.com/api/v1.0/email/send",
        data:{
            service_id: 'service_p3hl628',
            template_id: 'template_jczzt3e',
            user_id: 'JF8bxWeM3ppPYTcGj',
            template_params: {
                'username': response.name,
                'email': response.email,
                "subject": response.subject,
                "message":response.message
            }
        },
        headers: {
        "Content-Type": "application/json",
        }
      })

.then(res=>{

    setShowMessage("success")
    setResponse({
        username:"",
        email:"",
        subject:"",
        message:""
    })
    
    
}).catch(err=>{
    // alert('Oops... ' + JSON.stringify(err));
    setShowMessage("error")
    console.log(err)
})

}


  return (    
        <main className="main-aboutpage">
    
          <Header/>
    
            <section className="contact-area">
                <div className="container">
                    <div className="gx-row d-flex justify-content-between gap-24">
                        <div className="contact-infos">
                            <h3 data-aos="fade-up">Contact Info</h3>
                            <ul className="contact-details">
                                <li className="d-flex align-items-center" data-aos="zoom-in">
                                    <div className="icon-box shadow-box">
                                        <i className="iconoir-mail"></i>
                                    </div>
                                    <div className="right">
                                        <span>MAIL us</span>
                                        <h4>syedhamzahoda@gmail.com</h4>
                                        <h4>hamza@ypayfinancial.com</h4>
                                    </div>
                                </li>
    
                                <li className="d-flex align-items-center" data-aos="zoom-in">
                                    <div className="icon-box shadow-box">
                                        <i className="iconoir-phone"></i>
                                    </div>
                                    <div className="right">
                                        <span>Contact Us</span>
                                        <h4>+92 332-379-6884</h4>
                                        {/* <h4>+1 504-749-5456</h4> */}
                                    </div>
                                </li>
    
                                <li className="d-flex align-items-center" data-aos="zoom-in">
                                    <div className="icon-box shadow-box">
                                        <i className="iconoir-pin-alt"></i>
                                    </div>
                                    <div className="right">
                                        <span>Location</span>
                                        <h4>Karachi, <br/>Sindh <br/>Pakistan</h4>
                                    </div>
                                </li>
                            </ul>
    
                            <h3 data-aos="fade-up">Social Info</h3>
                            <ul className="social-links d-flex align-center" data-aos="zoom-in">
                                <li><a className="shadow-box" target='__blank' href="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/"><i className="iconoir-linkedin"></i></a></li>
                                {/* <li><a className="shadow-box" target='__blank' href="https://twitter.com/syedhamzahoda"><i className="iconoir-twitter"></i></a></li> */}
                                <li><a className="shadow-box" target='__blank' href="https://www.instagram.com/syedhamzahoda/"><i className="iconoir-instagram"></i></a></li>
                                <li><a className="shadow-box" target='__blank' href="https://www.github.com/hamzahoda"><i className="iconoir-github-circle"></i></a></li>
                            </ul>
                        </div>
    
                        <div data-aos="zoom-in" className="contact-form">
                            <div className="shadow-box">
                                <img src={Bg1} alt="BG" className="bg-img"/>
                                <img src={Icon3} alt="Icon"/>
                                <h1>Let’s work <span>together.</span></h1>
                                <form >
                                   
                                   {showMessage =="success" ? <div className="alert alert-success messenger-box-contact__msg"  role="alert">
                                        Your message was sent successfully.
                                    </div>
                                    :
                                    showMessage == "error" ? <div className="alert alert-success messenger-box-contact__msg" style={{color:"red"}} role="alert">
                                        Error sending message, Please try again.
                                    </div>    

                                    : null
                                }

                                    <div className="input-group">
                                        <input value={response.username} onChange={(e)=>setResponse({...response,name:e.target.value})} type="text" name="full-name" id="full-name" placeholder="Name *"/>
                                    </div>
                                    <div className="input-group">
                                        <input value={response.email} onChange={(e)=>setResponse({...response,email:e.target.value})}  type="email" name="email" id="email" placeholder="Email *"/>
                                    </div>
                                    <div className="input-group">
                                        <input value={response.subject} onChange={(e)=>setResponse({...response,subject:e.target.value})}  type="text" name="subject" id="subject" placeholder="Your Subject *"/>
                                    </div>
                                    <div className="input-group">
                                        <textarea value={response.message} onChange={(e)=>setResponse({...response,message:e.target.value})}  name="message" id="message" placeholder="Your Message *"></textarea>
                                    </div>
                                    <div className="input-group">
                                        <button onClick={()=>sendEmail()} className="theme-btn submit-btn" name="submit" type="button">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
    
    
           <Footer/>
    
        </main>
        
    
  )
}
